import React, { useContext } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import Scrollbars from "react-custom-scrollbars-2";
import { grey } from "@material-ui/core/colors";
import { plainApi } from "../api";
import { UserContext } from "../context/UserContext";
import { logout } from '../services/AuthenticationService';
import TermsOfUseContent from "./TermsOfUseContent";

const TermsOfUseModal = () => {

  const [userState, userDispatch] = useContext(UserContext);

  const api = plainApi();

  const acceptTOS = () => {
    api.post('tos/accept', userState.token).then(() => userDispatch({
      type: 'TOS',
      tosStatus: false,
    }));
  }
  
  const declineTOS = () => {
    api.post('tos/decline', userState.token).then(() => userDispatch({
      type: 'TOS',
      tosStatus: true,
    }));
    logout();
  }
  return (
    <div>
      <Modal className="gw__tos" isOpen={true} scrollable={true} centered size="sm" style={{minHeight: '50vh'}}>
        <ModalHeader className="pb-0">
          <div className="gw__landing-page-tos-title">Terms of Use</div>
        </ModalHeader>
        <ModalBody className="landing-modal-body">
          <Scrollbars style={{ height: '100%', backgroundColor: grey }} thumbMinSize={150}>
            <h5 className="font-weight-extra-bold mt-0">
              PLEASE READ THESE TERMS OF USE CAREFULLY BEFORE CLICKING THE “I ACCEPT” BUTTON.
            </h5>
            <div>
              <TermsOfUseContent />
            </div>
          </Scrollbars>
        </ModalBody>
        <ModalFooter>
          <div className="gw__tos-footer px-2 my-2">
            <button className="btn btn-dark mx-1" disabled="" onClick={declineTOS}>
              DECLINE
            </button>
            <button className="btn btn-primary mx-1" disabled="" onClick={acceptTOS}>
              I ACCEPT
            </button>
          </div>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default TermsOfUseModal;
